import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";

import "./css/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./utils/slideshowAnimations";

import Diaporama from "./screens/Diaporama";
import { PhotoWall } from "./screens/PhotoWall";

import ErrorPage from "./screens/404";
import Home from "./screens/Home";

import reportWebVitals from "./reportWebVitals";
import initI18n from "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="" exact element={<Home />} />
        <Route path="diaporama/:code" element={<Diaporama />} />
        <Route path="photowall/:code" element={<PhotoWall />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

initI18n();
